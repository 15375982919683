export enum UserPermissions {
  ViewStores = 'ViewStores',
  MaintainStores = 'MaintainStores',
  ViewMenus = 'ViewMenus',
  MaintainMenus = 'MaintainMenus',
  ViewItems = 'ViewItems',
  MaintainItems = 'MaintainItems',
  ViewIngredients = 'ViewIngredients',
  MaintainIngredients = 'MaintainIngredients',
  ViewVarieties = 'ViewVarieties',
  MaintainVarieties = 'MaintainVarieties',
}